import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './icon.module.styl';

import add from './add.svg';
import appointmentsBook from './order.svg';
import calendar from './calendar.svg';
import cash from './cash.svg';
import check from './check.svg';
import checkCircleActive from './check-circle-active.svg';
import checkCircleInactive from './check-circle-inactive.svg';
import close from './close.svg';
import correction from './correction.svg';
import disabled from './disabled.svg';
import dislike from './dislike.svg';
import declined from './declined.svg';
import doorDashIcon from './doordash.svg';
import downArrow from './down-arrow.svg';
import edit from './edit.svg';
import email from './email.svg';
import exit from './exit.svg';
import expand from './expand.svg';
import expandArrow from './expand-arrow.svg';
import google from './google.svg';
import grid from './grid.svg';
import help from './help.svg';
import infinity from './infinity.svg';
import leftArrow from './left-arrow.svg';
import like from './like.svg';
import limitlessPlus from './limitless-plus.svg';
import mountains from './mountains.svg';
import move from './move.svg';
import moveArrow from './move-arrow.svg';
import negativeIcon from './negative-icon.svg';
import path from './path.svg';
import pause from './pause.svg';
import phone from './phone.svg';
import pos from './pos.svg';
import product from './product.svg';
import refresh from './refresh.svg';
import rightArrow from './right-arrow.svg';
import sad from './sad.svg';
import search from './search.svg';
import search2 from './search2.svg';
import selectedCheckbox from './selected-checkbox.svg';
import tube from './tube.svg';
import tubeHorizontal from './tube-horizontal.svg';
import turnLeft from './turn-left.svg';
import upArrow from './up-arrow.svg';
import runningLate from './running-late-bg.svg';
import heartsIcon from './hearts-icon.svg';
import madisonReedHcb from './Madison-reed-HCB.svg';
import declinedCircle from './declined-circle.svg';
import receipt from './receipt.svg';
import trash from './trash.svg';
import target from './target.svg';
import infoCircle from './info-circle.svg';

const cx = classnames.bind(styles);

const iconNameToIconSvg = {
  add,
  appointmentsBook,
  calendar,
  cash,
  check,
  checkCircleActive,
  checkCircleInactive,
  close,
  correction,
  disabled,
  dislike,
  declined,
  doorDashIcon,
  downArrow,
  edit,
  email,
  exit,
  expand,
  expandArrow,
  google,
  grid,
  help,
  infinity,
  leftArrow,
  like,
  limitlessPlus,
  mountains,
  move,
  moveArrow,
  negativeIcon,
  path,
  pause,
  phone,
  pos,
  product,
  refresh,
  rightArrow,
  sad,
  search,
  search2,
  selectedCheckbox,
  tube,
  tubeHorizontal,
  turnLeft,
  upArrow,
  runningLate,
  heartsIcon,
  madisonReedHcb,
  declinedCircle,
  receipt,
  trash,
  target,
  infoCircle,
};

export const iconNames = _.reduce(iconNameToIconSvg, (acc, svg, name) => {
  acc[name] = name;
  return acc;
}, {});

export const colors = [
  'white',
  'silver',
  'dusty-gray',
  'dove-gray',
  'danube-blue',
  'purple',
  null,
];

/** @component */
export default function Icon({
  bgColor = 'transparent',
  className,
  color,
  iconName,
  onClick,
  size,
  useCurrentColor,
  'data-mr-ass': dataMrAss,
}) {
  const iconSvg = iconNameToIconSvg[iconName];

  return (
    <div
      className={
        cx(
          'icon',
          {
            [`icon-${color}`]: !useCurrentColor && color,
            'use-current-color': useCurrentColor || !color,
          },
          `bg-${bgColor}`,
          size,
          className,
        )
      }
      dangerouslySetInnerHTML={{ __html: iconSvg }}
      onClick={onClick}
      data-mr-ass={dataMrAss}
    />
  );
}

Icon.propTypes = {
  bgColor: PropTypes.oneOf(['white', 'purple', null]),
  className: PropTypes.string,
  color: PropTypes.oneOf(colors),
  iconName: PropTypes.oneOf(Object.keys(iconNames)).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'small', null]),
  useCurrentColor: PropTypes.bool,
  ['data-mr-ass'] : PropTypes.string,
};
Icon.iconNames = iconNames;
